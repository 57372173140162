import { get } from 'lib/api'
import { CapsuleState, UserState } from 'lib/types/models'
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { AppState } from 'store'
import { VerifyAccount } from 'components/VerifyAccount'
import Lottie from 'react-lottie'
import { CapsuleLocked } from './components/CapsuleLocked'
import { CapsuleOpen } from './components/CapsuleOpen'
import Logo from '../../lib/images/logo-full.png'
import animationData from '../../animations/connecting-2.json'

const EVENT_CAPSULES = [
  '76365a22-7e88-44de-89f8-68bac77fd77b',
  '1e8f95b9-91df-4405-8067-9e4dd88438ca',
  '6b4e3b1d-d2c7-4fcc-b2df-79d9151f1cf2',
  '6401350d-22d0-43e7-a685-6125bc13564f',
  'bafbbd26-348c-4aeb-8a5d-d579b691f713',
  'a247afca-0891-4b7e-80cc-8e8786f4fc1b',
  'ecde9375-929c-48fb-82bb-17f839e94413',
  '55d8c1d5-b362-4886-8473-3476a8aabf41',
  'a13c1d78-8451-480c-933e-7212f12acd1a',
  '6a337da8-938d-4c88-864f-a8703c75a720',
  '40a50972-ab97-4bc0-81e1-7396fafaebc1',
  '4f029d60-b40a-4930-a62e-5c16cd4c7bb6',
  'c7439e21-cdf2-4250-9fb8-dea7ecb58319',
  'a1d8337f-72aa-4974-9111-ac38ad3784c3',
  '0d237786-521a-4d8d-a243-14cd599c1f8b',
  '2a3ec9c4-4111-47f4-81d3-fd44d0da6f77',
  '53118aa4-68db-492e-a14c-2e9ca5583294',
  '20fa3dc9-a7df-48b1-985c-a1bc4bde195f',
  'b32bc05b-e6c7-412c-b72f-89b6063f7e81',
  'd31f7ba6-a8db-40cc-acd7-0e1cf98bac85',
]

const TIME = 2000

const Capsule: React.FC = () => {
  const ref = useRef<HTMLDivElement>(null)

  const [loading, setLoading] = useState(true)
  const [timeIsOver, setTimeIsOver] = useState(false)
  const [capsule, setCapsule] = useState<CapsuleState | null>(null)

  const params = useParams()
  const navigate = useNavigate()

  const user = useSelector<AppState, UserState>(({ user }) => user)

  const fetchCapsule = async () => {
    try {
      const { data } = await get(`/capsules/get-capsule/${params.uuid}`)

      if (!user.authenticated) {
        if (!data.sender_id) {
          navigate(`/register?capsule=${params.uuid}`)

          return
        }

        if (data.receiver_id || !data.locked) {
          navigate(`/login?capsule=${params.uuid}`)

          return
        }
      }

      if (user.authenticated) {
        if (!data.sender_id) {
          navigate(`/login?capsule=${params.uuid}`)

          return
        }

        if (data.sender_id === user.id) {
          navigate('/success')

          return
        }

        if (data.sender_id !== user.id && data.receiver_id !== user.id) {
          navigate(`/login?capsule=${params.uuid}`)
        }
      }

      setLoading(false)

      setCapsule(data)

      setTimeIsOver(new Date(data.opening_date) < new Date())
    } catch {
      try {
        const { data } = await get(`/event/get-event/${params.uuid}`)

        window.location.href = `/event?code=${data.code}`
      } catch {
        navigate('/404')
      }
    }
  }

  useEffect(() => {
    setTimeout(() => {
      if (!ref.current) {
        return
      }

      ref.current.style.opacity = '1'
    }, 100)

    setTimeout(() => {
      if (!ref.current) {
        return
      }

      ref.current.style.opacity = '0'
    }, TIME + 200)

    if (!EVENT_CAPSULES.includes(params.uuid || '')) {
      setTimeout(() => {
        fetchCapsule()
      }, TIME + 350)
    } else if (params.uuid === 'a1d8337f-72aa-4974-9111-ac38ad3784c3') {
      window.location.href = '/event?code=SDW9LX'
    } else {
      window.location.href = '/event'
    }
  }, [ref])

  if (loading) {
    return (
      <div
        className="flex flex-col items-center justify-center h-full opacity-0 transition-opacity duration-[250ms]"
        ref={ref}
      >
        <div className="w-full absolute top-[30px] left-0 flex justify-center">
          <img src={Logo} alt="logo" width={200} />
        </div>
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData,
          }}
          height={200}
          width={200}
        />

        <div className="text-[20px] leading-[1.6] text-dark-500 font-medium">
          Ürününle Bağlantı Sağlanıyor
        </div>
      </div>
    )
  }

  if (!capsule || !capsule.locked) {
    return <Navigate to="/404" replace />
  }

  if (user.authenticated && !user.is_verified) {
    return <VerifyAccount />
  }

  if (!timeIsOver) {
    return (
      <CapsuleLocked
        capsule={capsule}
        onTimeIsOver={() => setTimeIsOver(true)}
      />
    )
  }

  return <CapsuleOpen capsule={capsule} />
}

export default Capsule
