import { AxiosError } from 'axios'
import { post } from 'lib/api'
import { ValidationError } from 'lib/types/errors'
import getError from 'lib/utils/getError'
import React, { useEffect, useRef, useState } from 'react'
import InputMask from 'react-input-mask'
import { useDispatch } from 'react-redux'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { LOGIN } from 'store/actions'

const Register: React.FC = () => {
  const containerRef = useRef<HTMLDivElement>(null)

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState<ValidationError[]>([])

  const buttonDisabled =
    !name || !phone || phone === '___ ___ ____' || !email || !password

  const onNameChange = (value: string) => {
    setName(value)

    setErrors(errors.filter((error) => error.field !== 'full_name'))
  }

  const onPhoneChange = (value: string) => {
    setPhone(value.replace(/ /g, ''))

    setErrors(errors.filter((error) => error.field !== 'phone_number'))
  }

  const onEmailChange = (value: string) => {
    setEmail(value.toLowerCase())

    setErrors(errors.filter((error) => error.field !== 'email'))
  }

  const onPasswordChange = (value: string) => {
    setPassword(value)

    setErrors(errors.filter((error) => error.field !== 'password'))
  }

  const onSubmit = async () => {
    if (buttonDisabled) {
      return
    }

    if (
      // eslint-disable-next-line no-restricted-globals
      !confirm(
        'Sözleşmeleri okuyup anladığınız anlamına gelir. Onaylıyorsanız devam edebilirsiniz.'
      )
    ) {
      return
    }

    setLoading(true)
    setErrors([])

    try {
      const { data } = await post('/register', {
        full_name: name,
        phone_number: phone,
        agreement: true,
        email: email.toLowerCase(),
        password,
      })

      window.localStorage.setItem('token', data.token)

      dispatch({
        type: LOGIN,
        payload: data.user,
      })

      if (searchParams.get('capsule')) {
        navigate(
          `/otp?capsule=${searchParams.get('capsule')}&phone_number=${phone}`
        )
      }

      if (searchParams.get('event')) {
        navigate(
          `/otp?event=${searchParams.get('event')}&phone_number=${phone}`
        )
      }
    } catch (e) {
      const error = e as AxiosError

      if (error.response) {
        const data = error.response.data as {
          errors: ValidationError[]
        }

        setErrors(data.errors)
      }
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setTimeout(() => {
      if (!containerRef.current) {
        return
      }

      containerRef.current.style.opacity = '1'
    }, 100)
  }, [containerRef])

  return (
    <div className="flex flex-col h-full bg-[#030303]">
      <div className="flex items-center justify-center text-white h-[65px] text-[22px] leading-[28px] mb-[16px]">
        Üye ol
      </div>

      <div className="flex flex-col justify-between flex-1">
        <div className="flex flex-col gap-[14px] px-[20px]">
          <div className="flex flex-col gap-[8px]">
            <input
              placeholder="Ad Soyad"
              className="h-[56px] rounded-full bg-transparent border border-[#414141] text-[17px] leading-[22px] text-[#fff] px-[20px] text-[15px] leading-[20px] placeholder:text-[rgba(255,255,255,0.3)] outline-none bg-[#090909]"
              onChange={(e) => onNameChange(e.target.value)}
            />
            {getError(errors, 'full_name') && (
              <div className="text-error text-[15px] leading-[20px] px-[20px]">
                {getError(errors, 'full_name')}
              </div>
            )}
          </div>
          <div className="flex flex-col gap-[8px]">
            <input
              placeholder="Mail Adresi"
              className="h-[56px] rounded-full bg-transparent border border-[#414141] text-[17px] leading-[22px] text-[#fff] px-[20px] text-[15px] leading-[20px] placeholder:text-[rgba(255,255,255,0.3)] outline-none bg-[#090909]"
              onChange={(e) => onEmailChange(e.target.value)}
              type="email"
            />
            {getError(errors, 'email') && (
              <div className="text-error text-[15px] leading-[20px] px-[20px]">
                {getError(errors, 'email')}
              </div>
            )}
          </div>
          <div className="flex flex-col gap-[8px]">
            <InputMask
              mask="999 999 9999"
              className="h-[56px] rounded-full bg-transparent border border-[#414141] text-[17px] leading-[22px] text-[#fff] px-[20px] text-[15px] leading-[20px] placeholder:text-[rgba(255,255,255,0.3)] outline-none bg-[#090909]"
              placeholder="Telefon Numarası"
              value={phone}
              onChange={(e) => onPhoneChange(e.target.value)}
            />
            {getError(errors, 'phone_number') && (
              <div className="text-error text-[15px] leading-[20px] px-[20px]">
                {getError(errors, 'phone_number')}
              </div>
            )}
          </div>
          <div className="flex flex-col gap-[8px]">
            <input
              placeholder="Şifre"
              className="h-[56px] rounded-full bg-transparent border border-[#414141] text-[17px] leading-[22px] text-[#fff] px-[20px] text-[15px] leading-[20px] placeholder:text-[rgba(255,255,255,0.3)] outline-none bg-[#090909]"
              onChange={(e) => onPasswordChange(e.target.value)}
              type="password"
            />
            {getError(errors, 'password') && (
              <div className="text-error text-[15px] leading-[20px] px-[20px]">
                {getError(errors, 'password')}
              </div>
            )}
          </div>
          <div className="text-[#7E7E7E] text-[15px] leading-[20px] mt-[12px]">
            Zaten hesabın var mı?{' '}
            <Link
              to={
                searchParams.get('capsule')
                  ? `/login?capsule=${searchParams.get('capsule')}`
                  : searchParams.get('event')
                  ? `/login?event=${searchParams.get('event')}`
                  : '/login'
              }
              className="text-[#fff]"
            >
              Giriş yap
            </Link>
          </div>
        </div>
        <div className="px-[20px] py-[16px]">
          <button
            className="flex items-center justify-center w-full h-[56px] bg-[#fff] rounded-full text-[17px] leading-[22px] text-[#030303] font-bold disabled:opacity-50 disabled:cursor-not-allowed transition-all duration-300"
            onClick={onSubmit}
            disabled={buttonDisabled || loading}
          >
            Oluştur
          </button>
          <div className="text-[#7E7E7E] text-[15px] leading-[20px] mt-[12px] text-center px-[12px]">
            Üyelik oluşturduğunuzda{' '}
            <a
              href="/uyelik_sozlesmesi.pdf#toolbar=0"
              target="_blank"
              className="text-[#fff]"
            >
              Açık rıza metnini,{' '}
            </a>
            <a
              href="/kvkk.pdf#toolbar=0"
              target="_blank"
              className="text-[#fff]"
            >
              KVKK metnini
            </a>{' '}
            &{' '}
            <a
              href="/cerez_politikasi.pdf#toolbar=0"
              target="_blank"
              className="text-[#fff]"
            >
              Çerez politikası metnini
            </a>{' '}
            kabul etmiş olacaksınız.
          </div>
        </div>
      </div>
    </div>
  )
}

export default Register
