import React from 'react'

type Props = {
  eventName?: string
}

const Header: React.FC<Props> = ({ eventName }) => {
  return (
    <div className="flex items-center justify-center w-full h-[70px] px-[20px] shrink-0">
      <div className="text-white text-[20px] leading-[34px] font-bold">
        {eventName}
      </div>
    </div>
  )
}

export default Header
