import React, { useRef, useState } from 'react'
import cx from 'classnames'
import { Link, useSearchParams } from 'react-router-dom'
import { post } from 'lib/api'
import { AxiosError } from 'axios'
import toast from 'react-hot-toast'
import { Upload } from './components/Upload'

const EventUpload: React.FC = () => {
  const imageInputRef = useRef<HTMLInputElement>(null)

  const [isUploading, setIsUploading] = useState(false)
  const [media, setMedia] = useState<
    {
      file: File
      type: 'image' | 'video' | 'audio' | 'note'
    }[]
  >([])

  const [searchParams] = useSearchParams()

  const onUpload = (payload: {
    file: File
    type: 'image' | 'video' | 'audio' | 'note'
  }) => {
    setMedia([...media, payload])
  }

  const onImageUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]

    setIsUploading(true)
    toast.loading('Yükleniyor...')

    if (file && searchParams.get('uuid')) {
      const formData = new FormData()

      formData.append('file', file)
      formData.append('type', 'image')
      formData.append('connection_type', 'event')
      formData.append('connection_id', searchParams.get('uuid') as string)

      try {
        await post('/media/upload', formData)

        onUpload({ file, type: 'image' })

        toast.dismiss()
        toast.success('Fotoğraf başarıyla yüklendi!')

        setIsUploading(false)

        imageInputRef.current!.value = ''
      } catch (e) {
        const error = e as AxiosError

        setIsUploading(false)

        if (error.response) {
          const data = error.response.data as {
            error: {
              status: number
              message: string
            }
          }

          toast.dismiss()

          toast.error(data.error.message)
        }
      }
    }
  }

  // ENERJISA2025
  if (searchParams.get('uuid') === '28e7c58d-1403-475c-a832-8b32675d1364') {
    return (
      <div className="w-full h-full bg-[#0191D7]">
        <div className="absolute top-0 left-0 right-0 w-full z-20">
          <img
            src="/christmas-light.png"
            alt="christmas-light"
            className="w-full"
          />
        </div>
        <div className="absolute top-[4%] left-0 right-0 w-full flex justify-center z-30">
          <img
            src="/enerjisa-text.png"
            alt="enerjisa-text"
            className="w-[42%]"
          />
        </div>
        <div
          className="absolute top-[20%] left-0 right-0 z-30 text-center text-white font-normal"
          style={{
            fontSize: '6vw',
          }}
        >
          Enerjisa Üretim Yılbaşı Partisi <br /> fotoğraf kapsülüne hoş geldin!
        </div>
        <div className="absolute top-1/2 -translate-y-1/2 left-0 right-0 z-30 text-center text-white">
          <div
            className="font-normal tracking-wider mb-[20px]"
            style={{
              fontSize: '5vw',
            }}
          >
            Sol alttaki fotoğraf ikonuna tıkla, <br /> partiden fotoğrafını seç,
            <span
              className="block font-normal tracking-normal"
              style={{
                fontSize: '4.5vw',
              }}
            >
              KAPSÜLE YÜKLE!
            </span>
          </div>
          <div
            className="leading-[1.3] font-normal"
            style={{
              fontSize: '4.5vw',
            }}
          >
            Her an kendini sahne ekranlarında <br /> görmeye hazır ol 😊
          </div>
        </div>
        <div className="absolute bottom-[15%] left-0 right-0 z-30 text-center">
          <div
            className="text-white font-normal leading-[1.3] mb-[10px]"
            style={{
              fontSize: '3.5vw',
            }}
          >
            Tek seferde 1'den fazla fotoğraf <br /> yüklemek istersen app'i
            indir!
          </div>
          <div
            className="text-white font-normal leading-[1.3] mb-[5px]"
            style={{
              fontSize: '4.5vw',
            }}
          >
            App İndirmek İçin
          </div>
          <Link
            to={`/register?event=${localStorage.getItem('event')}`}
            className="text-white bg-[#ED2D2D] inline-flex px-[30px] py-[10px] rounded-full font-normal"
            style={{
              fontSize: '5vw',
            }}
          >
            TIKLA
          </Link>
        </div>
        <div className="absolute bottom-0 left-0 right-0 w-full z-20">
          <img src="/bottom-decor.png" alt="bottom-decor" className="w-full" />
        </div>
        <div
          className={cx(
            'absolute left-[20px] bottom-[10%] w-[72px] h-[72px] z-40',
            {
              'pointer-events-none': isUploading,
            }
          )}
          onClick={() => imageInputRef.current?.click()}
        >
          <input
            ref={imageInputRef}
            type="file"
            accept="image/*"
            className="hidden"
            onChange={onImageUpload}
          />
          <img src="/upload-icon.png" alt="" />
        </div>
        <div className="absolute top-1/2 -translate-y-1/2 left-0 w-full h-0 pb-[100%] bg-[#1647BD] rounded-full scale-[1.85]" />
        <div className="absolute top-1/2 -translate-y-1/2 left-0 w-full h-0 pb-[100%] bg-[#005EA6] rounded-full scale-[0.9]" />
      </div>
    )
  }

  return (
    <div className="flex flex-col h-full transition-opacity duration-[250ms]">
      <div className="overflow-scroll flex-1 flex">
        {isUploading && (
          <div className="flex-1 flex items-center justify-center">
            Yükleniyor...
          </div>
        )}
        {!isUploading && !media.length && (
          <div className="flex-1 flex flex-col items-center p-[20px] pt-[40px]">
            <Link
              to={`/register?event=${localStorage.getItem('event')}`}
              className="font-medium"
            >
              Bilgiℹ️ <br />
              Text To Next App kullanarak medyalarını 40 kat daha hızlı
              yükleyebilir, çoklu medya yüklemesi yapabilir, bu etkinlikte
              içerisinde olduğun fotoğraflara ulaşabilirsin. <br />
              <span className="font-bold text-underline">
                App İndirmek İçin Tıkla
              </span>
            </Link>
          </div>
        )}
        {!isUploading && media.length && (
          <div className="flex flex-wrap h-[fit-content]">
            {media.map((item, index) => (
              <div
                key={index}
                className={cx(
                  'bg-[#E9DFDF] border-b border-b-white flex items-center justify-center',
                  {
                    'bg-[#DBDEEA]': item.type === 'audio',
                    'bg-[#E8D5A5]': item.type === 'note',
                    'border-l border-l-white border-r border-r-white':
                      index % 3 !== 0 && index % 3 !== 2,
                  }
                )}
                style={{
                  width: window.innerWidth / 3,
                  height: window.innerWidth / 3,
                }}
              >
                {item.type === 'image' && (
                  <img
                    src={URL.createObjectURL(item.file)}
                    alt=""
                    className="w-full h-full object-cover"
                  />
                )}
                {item.type === 'video' && (
                  <svg
                    width="48"
                    height="48"
                    viewBox="0 0 32 33"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M8.15105 6.5H15.8738C19.1032 6.5 21.3578 8.72538 21.3578 11.9146V21.0854C21.3578 24.2746 19.1032 26.5 15.8738 26.5H8.15105C4.92168 26.5 2.66699 24.2746 2.66699 21.0854V11.9146C2.66699 8.72538 4.92168 6.5 8.15105 6.5ZM26.611 9.67198C27.1963 9.37418 27.883 9.40531 28.4416 9.75726C29.0003 10.1079 29.3337 10.717 29.3337 11.383V21.6178C29.3337 22.2852 29.0003 22.893 28.4416 23.2436C28.1363 23.4344 27.795 23.5319 27.451 23.5319C27.1643 23.5319 26.8776 23.4642 26.6096 23.3275L24.6349 22.3312C23.9043 21.9603 23.4509 21.2158 23.4509 20.3887V12.6107C23.4509 11.7823 23.9043 11.0378 24.6349 10.6696L26.611 9.67198Z"
                      fill="#fff"
                    />
                  </svg>
                )}
                {item.type === 'audio' && (
                  <svg
                    width="48"
                    height="48"
                    viewBox="0 0 48 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M24.0004 43.9997V37.6777"
                      stroke="white"
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M24.0004 29.6962V29.6962C19.5122 29.6962 15.8768 26.0436 15.8768 21.5364V12.1619C15.8768 7.65464 19.5122 4 24.0004 4C28.4865 4 32.1219 7.65464 32.1219 12.1619V21.5364C32.1219 26.0436 28.4865 29.6962 24.0004 29.6962Z"
                      stroke="white"
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M40 21.6016C40 30.4792 32.836 37.6769 24 37.6769C15.1619 37.6769 8 30.4792 8 21.6016"
                      stroke="white"
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M26.1408 20.1874H32.1209"
                      stroke="white"
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M28.1379 13.5116H32.1169"
                      stroke="white"
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
      {!isUploading && (
        <Upload setIsUploading={setIsUploading} onUpload={onUpload} />
      )}
    </div>
  )
}

export default EventUpload
