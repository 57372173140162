import { Route, Routes as RoutesBase } from 'react-router-dom'
import React from 'react'
import type { AppState } from 'store'

import { Capsule } from 'components/Capsule'
import { Login } from 'components/Login'
import { Page404 } from 'components/Page404'
import { Register } from 'components/Register'
import { Success } from 'components/Success'
import { useSelector } from 'react-redux'
import { InitialLoading } from 'components/InitialLoading'
import { OTP } from 'components/OTP'
import { Connect } from 'components/Connect'
import { ForgotPassword } from 'components/ForgotPassword'
import { Event } from 'components/Event'
import { EventDetail } from 'components/EventDetail'
import { EventUpload } from 'components/EventUpload'
import { Photographer } from 'components/Photographer'
import { FaceMatch } from 'components/FaceMatch'
import { FaceMatchResult } from 'components/FaceMatchResult'
import { EventNew } from 'components/EventNew'
import { EventNewUpload } from 'components/EventNewUpload'
import { EventNewFaceMatch } from 'components/EventNewFaceMatch'

const Routes: React.FC = () => {
  const loading = useSelector<AppState, boolean>(({ loading }) => loading)

  if (loading) {
    return <InitialLoading />
  }

  return (
    <RoutesBase>
      <Route path="/register" element={<Register />} />
      <Route path="/login" element={<Login />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/success" element={<Success />} />
      <Route path="/otp" element={<OTP />} />
      <Route path="/connect" element={<Connect />} />
      <Route path="/event-new/:code" element={<EventNew />} />
      <Route path="/event-new/:code/upload" element={<EventNewUpload />} />
      <Route
        path="/event-new/:code/faceMatch"
        element={<EventNewFaceMatch />}
      />
      <Route path="/event" element={<Event />} />
      <Route path="/event/upload" element={<EventUpload />} />
      <Route path="/event/:code" element={<EventDetail />} />
      <Route path="/pro/photographer/:uuid" element={<Photographer />} />
      <Route path="/pro/faceMatch/:uuid" element={<FaceMatch />} />
      <Route path="/pro/faceMatch/result/:uuid" element={<FaceMatchResult />} />
      <Route path="/:uuid" element={<Capsule />} />
      <Route path="/404" element={<Page404 />} />
      <Route path="/" element={<Login />} />
    </RoutesBase>
  )
}

export default Routes
