import React, { useEffect, useRef, useState } from 'react'
import { get } from 'lib/api'
import { useNavigate, useParams, Link } from 'react-router-dom'
import toast from 'react-hot-toast'
import { AxiosError } from 'axios'
import { AppState } from 'store'
import { UserState } from 'lib/types/models'
import { useSelector } from 'react-redux'
import Lottie from 'react-lottie'
import animationData from '../../animations/connecting-2.json'
import Logo from '../../lib/images/logo-full.png'

const EventNew: React.FC = () => {
  const ref = useRef<HTMLDivElement>(null)
  const pageRef = useRef<HTMLDivElement>(null)

  const [loading, setLoading] = useState(false)
  const [eventName, setEventName] = useState('')

  const navigate = useNavigate()

  const user = useSelector<AppState, UserState>(({ user }) => user)

  const params = useParams()

  const { code } = params

  const fetchEvent = async () => {
    setLoading(true)

    try {
      const { data } = await get(`/event/get-event/${code}`)

      setEventName(data.name)

      if (!user.authenticated) {
        if (!data.eventOwnerId) {
          navigate(`/register?event=${code}`)

          return
        }

        if (!data.locked) {
          if (ref.current) {
            ref.current.style.opacity = '0'
          }

          setTimeout(() => {
            setLoading(false)

            setTimeout(() => {
              if (pageRef.current) {
                pageRef.current.style.opacity = '1'
              }
            })
          }, 50)

          return
        }

        if (data.locked) {
          navigate(`/login?event=${code}`)

          return
        }
      }

      if (user.authenticated) {
        if (!data.locked) {
          if (ref.current) {
            ref.current.style.opacity = '0'
          }

          setTimeout(() => {
            setLoading(false)

            setTimeout(() => {
              if (pageRef.current) {
                pageRef.current.style.opacity = '1'
              }
            })
          }, 50)

          return
        }

        navigate(`/login?event=${code}`)
      }
    } catch (e) {
      const error = e as AxiosError

      if (error.response) {
        if (error.response.status === 404) {
          navigate('/404')

          return
        }

        const data = error.response.data as {
          error: {
            status: number
            message: string
          }
        }

        toast.error(data.error.message)
      }
    }
  }

  const onClick = () => {
    if (pageRef.current) {
      pageRef.current.style.transitionDuration = '750ms'
    }

    setTimeout(() => {
      if (pageRef.current) {
        pageRef.current.style.opacity = '0'
      }

      setTimeout(() => {
        navigate(`/event-new/${code}/upload`)
      }, 700)
    })
  }

  useEffect(() => {
    if (!code) {
      return
    }

    fetchEvent()
  }, [code])

  if (loading) {
    return (
      <div
        className="flex flex-col items-center justify-center h-full opacity-0 transition-opacity duration-[250ms]"
        ref={ref}
      >
        <div className="w-full absolute top-[30px] left-0 flex justify-center">
          <img src={Logo} alt="logo" width={200} />
        </div>
        {/* @ts-ignore */}
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData,
          }}
          height={200}
          width={200}
        />

        <div className="text-[20px] leading-[1.6] text-dark-500 font-medium">
          Etkinlik ile bağlantı sağlanıyor
        </div>
      </div>
    )
  }

  return (
    <div
      className="h-full flex items-end"
      style={{
        backgroundImage: 'url(/event.png)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        opacity: 1,
      }}
    >
      <div
        className="px-[40px] pt-[139px] pb-[40px] w-full opacity-0 transition-opacity duration-[250ms]"
        style={{
          background:
            'linear-gradient(180deg, rgba(3, 3, 3, 0.00) -4.96%, rgba(3, 3, 3, 0.43) 7.74%, #030303 40.46%, #030303 100%)',
        }}
        ref={pageRef}
      >
        <div className="text-[28px] leading-[34px] text-white font-light text-center mb-[26px] px-[40px]">
          {eventName}
        </div>
        <div
          className="flex justify-center items-center w-full bg-[#fff] text-[20px] leading-[22px] text-[#030303] font-bold rounded-[100px] px-[54px] py-[20px] text-center"
          onClick={onClick}
        >
          Etkinliğe Katılın
        </div>
      </div>
    </div>
  )
}

export default EventNew
