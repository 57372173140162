import React, { useEffect, useRef, useState } from 'react'
import { useParams, Link, useNavigate } from 'react-router-dom'
import { Header } from './components/Header'

const EventNewFaceMatch: React.FC = () => {
  const pageRef = useRef<HTMLDivElement>(null)
  const bodyRef = useRef<HTMLDivElement>(null)
  const scanRef = useRef<HTMLDivElement>(null)
  const successRef = useRef<HTMLDivElement>(null)

  const [showScan, setShowScan] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const [file, setFile] = useState<File | null>(null)

  const ref = useRef<HTMLInputElement>(null)

  const params = useParams()

  const { code } = params

  const navigate = useNavigate()

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target

    if (files?.length) {
      setFile(files[0])
    }
  }

  useEffect(() => {
    setTimeout(() => {
      if (pageRef.current) {
        pageRef.current.style.opacity = '1'
      }
    }, 10)
  }, [code])

  const onClick = (route: string) => {
    if (pageRef.current) {
      pageRef.current.style.opacity = '0'
    }

    setTimeout(() => {
      navigate(`/event-new/${code}/${route}`)
    }, 250)
  }

  return (
    <div
      className="flex flex-col h-full"
      style={{
        backgroundImage: `url('/bg.png')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center bottom',
      }}
    >
      <Header eventCode={code} />
      <div
        className="flex flex-col h-full opacity-0 transition-opacity duration-[250ms]"
        ref={pageRef}
      >
        <div className="relative h-full flex flex-col">
          <div
            ref={bodyRef}
            className="flex-1 flex flex-col transition-opacity duration-[250ms]"
          >
            <div className="p-[20px] flex items-center gap-[8px]">
              <div onClick={() => onClick('upload')} className="flex-1">
                <div className="flex items-center gap-[10px] bg-[#fff] h-[40px] px-[10px] rounded-[10px] relative">
                  <svg
                    width="25"
                    height="25"
                    viewBox="0 0 25 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M7.37028 2.15991H16.7003C20.1003 2.15991 22.0403 4.07991 22.0403 7.48991V16.8299C22.0403 20.2199 20.1103 22.1599 16.7103 22.1599H7.37028C3.96028 22.1599 2.04028 20.2199 2.04028 16.8299V7.48991C2.04028 4.07991 3.96028 2.15991 7.37028 2.15991ZM12.8603 12.9899H15.7003C16.1603 12.9799 16.5303 12.6099 16.5303 12.1499C16.5303 11.6899 16.1603 11.3199 15.7003 11.3199H12.8603V8.49991C12.8603 8.03991 12.4903 7.66991 12.0303 7.66991C11.5703 7.66991 11.2003 8.03991 11.2003 8.49991V11.3199H8.37028C8.15028 11.3199 7.94028 11.4099 7.78028 11.5599C7.63028 11.7199 7.54028 11.9289 7.54028 12.1499C7.54028 12.6099 7.91028 12.9799 8.37028 12.9899H11.2003V15.8199C11.2003 16.2799 11.5703 16.6499 12.0303 16.6499C12.4903 16.6499 12.8603 16.2799 12.8603 15.8199V12.9899Z"
                      fill="#ABB7C2"
                    />
                  </svg>
                  <div className="text-[#ABB7C2] text-[11px] leading-[16px]">
                    Yükle
                  </div>
                </div>
              </div>
              <div className="flex-1">
                <div className="flex items-center gap-[10px] bg-[#000] h-[40px] px-[10px] rounded-[10px] relative">
                  <svg
                    width="27"
                    height="27"
                    viewBox="0 0 27 27"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.39868 9.03639V6.11928C4.39868 4.73836 5.51814 3.6189 6.89906 3.6189H9.67727"
                      stroke="#fff"
                      strokeWidth="0.986993"
                      strokeLinecap="round"
                    />
                    <path
                      d="M4.39868 17.0932V20.0104C4.39868 21.3913 5.51814 22.5107 6.89906 22.5107H9.67727"
                      stroke="#fff"
                      strokeWidth="0.986993"
                      strokeLinecap="round"
                    />
                    <path
                      d="M23.1514 9.03639V6.11928C23.1514 4.73836 22.0319 3.6189 20.651 3.6189H17.8728"
                      stroke="#fff"
                      strokeWidth="0.986993"
                      strokeLinecap="round"
                    />
                    <path
                      d="M23.1514 17.0932V20.0104C23.1514 21.3913 22.0319 22.5107 20.651 22.5107H17.8728"
                      stroke="#fff"
                      strokeWidth="0.986993"
                      strokeLinecap="round"
                    />
                    <path
                      d="M16.9006 17.042L16.4831 17.2275C14.7591 17.9938 12.7912 17.9938 11.0671 17.2275L10.6497 17.042"
                      stroke="#fff"
                      strokeWidth="0.986993"
                      strokeLinecap="round"
                    />
                    <path
                      d="M14.1883 10.0967V13.5232C14.1883 14.0481 13.8211 14.5014 13.3076 14.6103L12.8723 14.7026"
                      stroke="#fff"
                      strokeWidth="0.986993"
                      strokeLinecap="round"
                    />
                    <path
                      d="M9.81616 10.0967L9.81616 11.7417"
                      stroke="#fff"
                      strokeWidth="0.986993"
                      strokeLinecap="round"
                    />
                    <path
                      d="M17.873 10.0967L17.873 11.7417"
                      stroke="#fff"
                      strokeWidth="0.986993"
                      strokeLinecap="round"
                    />
                  </svg>
                  <div className="text-[#fff] text-[11px] leading-[16px]">
                    Yüz Tanıma
                  </div>
                  <div className="absolute bottom-[-15px] left-[50%] w-[calc(100%-20px)] h-[5px] bg-[#000] rounded-[10px] translate-x-[-50%]" />
                </div>
              </div>
              <div onClick={() => onClick('story')} className="flex-1">
                <div className="flex items-center gap-[10px] bg-[#fff] h-[40px] px-[10px] rounded-[10px]">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M8.09756 12C8.09756 14.1333 9.8439 15.8691 12 15.8691C14.1463 15.8691 15.8927 14.1333 15.8927 12C15.8927 9.85697 14.1463 8.12121 12 8.12121C9.8439 8.12121 8.09756 9.85697 8.09756 12ZM17.7366 6.04606C19.4439 7.36485 20.8976 9.29455 21.9415 11.7091C22.0195 11.8933 22.0195 12.1067 21.9415 12.2812C19.8537 17.1103 16.1366 20 12 20H11.9902C7.86341 20 4.14634 17.1103 2.05854 12.2812C1.98049 12.1067 1.98049 11.8933 2.05854 11.7091C4.14634 6.88 7.86341 4 11.9902 4H12C14.0683 4 16.0293 4.71758 17.7366 6.04606ZM12.0012 14.4124C13.3378 14.4124 14.4304 13.3264 14.4304 11.9979C14.4304 10.6597 13.3378 9.57362 12.0012 9.57362C11.8841 9.57362 11.767 9.58332 11.6597 9.60272C11.6207 10.6694 10.7426 11.5227 9.65971 11.5227H9.61093C9.58166 11.6779 9.56215 11.833 9.56215 11.9979C9.56215 13.3264 10.6548 14.4124 12.0012 14.4124Z"
                      fill="#ABB7C2"
                    />
                  </svg>
                  <div className="text-[#C0C9D1] text-[11px] leading-[16px]">
                    Story'ler
                  </div>
                </div>
              </div>
            </div>

            <div className="flex-1 flex flex-col items-center justify-center transition-opacity duration-[250ms]">
              <div
                className="w-[130px] h-[130px] flex flex-col items-center justify-center gap-[8px] relative"
                onClick={() => {
                  setShowScan(true)

                  setTimeout(() => {
                    if (scanRef.current && bodyRef.current) {
                      scanRef.current.style.opacity = '1'
                      bodyRef.current.style.opacity = '0'
                    }
                  }, 10)
                }}
              >
                <div className="absolute top-0 left-0 w-full h-full">
                  <img src="/card-bg.png" alt="" />
                </div>
                <div className="flex flex-col items-center justify-center gap-[4px] relative z-10">
                  <svg
                    width="69"
                    height="69"
                    viewBox="0 0 69 69"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.3499 23.69V16.0425C10.3499 12.4223 13.2847 9.48755 16.9049 9.48755H24.1882"
                      stroke="white"
                      strokeWidth="2.5875"
                      strokeLinecap="round"
                    />
                    <path
                      d="M10.35 44.8119V52.4594C10.35 56.0796 13.2847 59.0144 16.905 59.0144H24.1883"
                      stroke="white"
                      strokeWidth="2.5875"
                      strokeLinecap="round"
                    />
                    <path
                      d="M59.5125 23.69V16.0425C59.5125 12.4223 56.5777 9.48755 52.9575 9.48755H45.6741"
                      stroke="white"
                      strokeWidth="2.5875"
                      strokeLinecap="round"
                    />
                    <path
                      d="M59.5125 44.8119V52.4594C59.5125 56.0796 56.5777 59.0144 52.9575 59.0144H45.6741"
                      stroke="white"
                      strokeWidth="2.5875"
                      strokeLinecap="round"
                    />
                    <path
                      d="M43.1249 44.6775L42.0305 45.1639C37.5108 47.1727 32.3516 47.1727 27.8319 45.1639L26.7374 44.6775"
                      stroke="white"
                      strokeWidth="2.5875"
                      strokeLinecap="round"
                    />
                    <path
                      d="M36.0142 26.469V35.452C36.0142 36.828 35.0515 38.0164 33.7054 38.3019L32.5642 38.544"
                      stroke="white"
                      strokeWidth="2.5875"
                      strokeLinecap="round"
                    />
                    <path
                      d="M24.5525 26.469L24.5525 30.7815"
                      stroke="white"
                      strokeWidth="2.5875"
                      strokeLinecap="round"
                    />
                    <path
                      d="M45.6743 26.469L45.6743 30.7815"
                      stroke="white"
                      strokeWidth="2.5875"
                      strokeLinecap="round"
                    />
                  </svg>

                  <div className="text-[13px] leading-[18px] font-light text-white">
                    Taramayı Başlat
                  </div>
                </div>
              </div>
              <div className="text-white text-[13px] leading-[18px] font-light text-center mt-[38px]">
                <b>Yüz tanıma ile</b> <br /> bu etkinlikte çekilen tüm
                fotoğraflarda <br />
                <b> içerisinde olduğun karelere ulaş!</b>
              </div>
            </div>
          </div>

          {showScan && (
            <div
              className="absolute top-0 left-0 w-full h-full flex flex-col items-center justify-center transition-opacity duration-[250ms] opacity-0 z-10"
              ref={scanRef}
            >
              <div
                className="w-[calc(100%-72px)] h-[75%] bg-[rgba(255,255,255,.10)] mx-auto mt-[24px] mb-[36px] rounded-[20px] border-[2px] border-[rgba(255,255,255,.30)] border-dashed flex flex-col items-center justify-center "
                onClick={() => ref.current?.click()}
                style={{
                  backgroundImage: `url(${
                    file ? URL.createObjectURL(file) : ''
                  })`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                }}
              >
                {file === null && (
                  <div
                    className="w-[130px] h-[130px] flex flex-col items-center justify-center gap-[8px] relative"
                    onClick={() => {
                      setShowScan(true)

                      setTimeout(() => {
                        if (scanRef.current && bodyRef.current) {
                          scanRef.current.style.opacity = '1'
                          bodyRef.current.style.opacity = '0'
                        }
                      }, 10)
                    }}
                  >
                    <div className="absolute top-0 left-0 w-full h-full">
                      <img src="/card-bg.png" alt="" />
                    </div>
                    <div className="flex flex-col items-center justify-center gap-[8px] relative z-10">
                      <svg
                        width="50"
                        height="42"
                        viewBox="0 0 50 42"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_2003_2159)">
                          <path
                            d="M40.4265 16.6804V16.3719C40.4265 7.73313 33.6389 0.945557 25.0002 0.945557C17.287 0.945557 10.808 6.49903 9.88242 14.2122C4.32895 16.0633 0.318115 20.9998 0.318115 27.1703C0.318115 34.8834 6.48864 41.0539 14.2018 41.0539C19.4467 41.0539 32.4048 41.0539 37.3412 41.0539C44.1288 41.0539 49.6823 35.5005 49.6823 28.7129C49.6823 22.8509 45.6714 18.223 40.4265 16.6804Z"
                            fill="#EEEEEE"
                            fillOpacity="0.933333"
                          />
                          <path
                            d="M28.0854 25.6277V34.8834H21.9148V25.6277H12.6591L25.0001 13.2866L37.3411 25.6277H28.0854Z"
                            fill="black"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_2003_2159">
                            <rect
                              width="49.3642"
                              height="40.1084"
                              fill="white"
                              transform="translate(0.318115 0.945557)"
                            />
                          </clipPath>
                        </defs>
                      </svg>

                      <div className="text-[13px] leading-[18px] font-light text-white text-center">
                        Fotoğraf Çek <br />
                        veya Yükle
                      </div>
                    </div>
                  </div>
                )}
                {file === null && (
                  <div className="text-white text-[13px] leading-[18px] font-light text-center mt-[38px] opacity-50">
                    Yüzünün net göründüğü <br />
                    bir fotoğraf çek.
                  </div>
                )}
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleFileChange}
                  className="w-full bg-transparent border border-white p-[16px] text-white text-lg font-semibold focus:outline-none"
                  ref={ref}
                  hidden
                />
              </div>

              {file && (
                <div className="flex items-center justify-center px-[20px] gap-[8px] w-full">
                  <button
                    className="bg-[#fff] text-[#030303] text-[14px] leading-[22px] text-center rounded-full px-[32px] py-[10px] font-bold flex-1"
                    onClick={() => ref.current?.click()}
                  >
                    Tekrar Çek
                  </button>
                  <button
                    className="bg-[#fff] text-[#030303] text-[14px] leading-[22px] text-center rounded-full px-[32px] py-[10px] font-bold flex-1"
                    onClick={() => {
                      if (scanRef.current) {
                        scanRef.current.style.opacity = '0'
                      }

                      setTimeout(() => {
                        setShowScan(false)
                        setShowSuccess(true)

                        if (ref.current) {
                          setFile(null)
                          ref.current.value = ''
                        }

                        setTimeout(() => {
                          if (successRef.current) {
                            successRef.current.style.opacity = '1'
                          }
                        }, 10)
                      }, 250)
                    }}
                  >
                    Onayla
                  </button>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange}
                    className="w-full bg-transparent border border-white p-[16px] text-white text-lg font-semibold focus:outline-none"
                    ref={ref}
                    hidden
                  />
                </div>
              )}
            </div>
          )}

          {showSuccess && (
            <div
              className="absolute top-0 left-0 w-full h-full flex flex-col items-center justify-center transition-opacity duration-[250ms] opacity-0 z-10"
              ref={successRef}
            >
              <img src="/success.png" alt="" className="translate-x-[25px]" />
              <div className="text-[24px] leading-[32px] font-bold text-white mt-[24px]">
                Hepsi tamam!
              </div>
              <div className="text-[18px] leading-[30px] font-light text-white text-center mt-[38px]">
                Etkinlik tamamlandığında, <br />
                içerisinde olduğun kareleri <br />
                <b className="font-bold">SMS</b> ve{' '}
                <b className="font-bold">MAİL</b> olarak sana <br />
                ulaştıracağız.
              </div>
              <button
                className="bg-[#fff] text-[#030303] text-[14px] leading-[22px] text-center rounded-full px-[50px] py-[10px] font-bold mt-[38px]"
                onClick={() => {
                  if (successRef.current && bodyRef.current) {
                    successRef.current.style.opacity = '0'
                    bodyRef.current.style.opacity = '1'
                  }

                  setTimeout(() => {
                    setShowSuccess(false)
                  }, 250)
                }}
              >
                Tamam
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default EventNewFaceMatch
