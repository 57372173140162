import { AxiosError } from 'axios'
import { get } from 'lib/api'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'

type Props = {
  eventCode?: string
}

const Header: React.FC<Props> = ({ eventCode }) => {
  const [eventName, setEventName] = useState('')

  const navigate = useNavigate()

  const fetchEvent = async () => {
    try {
      const { data } = await get(`/event/get-event/${eventCode}`)

      setEventName(data.name)
    } catch (e) {
      const error = e as AxiosError

      if (error.response) {
        if (error.response.status === 404) {
          navigate('/404')

          return
        }

        const data = error.response.data as {
          error: {
            status: number
            message: string
          }
        }

        toast.error(data.error.message)
      }
    }
  }

  useEffect(() => {
    if (!eventCode) {
      return
    }

    fetchEvent()
  }, [eventCode])

  return (
    <div className="flex items-center justify-center w-full h-[70px] px-[20px] shrink-0">
      <div className="text-white text-[20px] leading-[34px] font-bold">
        {eventName}
      </div>
    </div>
  )
}

export default Header
